import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'
import Workings from 'components/Wachtlijst/Workings'
import WachtlijstForm from 'components/Wachtlijst/WachtlijstForm'
import Modal from 'components/Modal'

// Third Party
import parse from 'html-react-parser'
import styled from 'styled-components'

const StyledImg = styled(Img)`
  height: 100%;
  z-index: -1;
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 767px) {
    margin-top: -80px;
  }
`

const ImageContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const Styledh2 = styled.h2``

const Wachtlijst = styled.div`
  ${Styledh2} {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.xxl};
    padding-bottom: 1.5rem;
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
  }
`

export const WachtlijstDetailTemplate = ({
  post,
  page,
  pageContext
}) => {
  return (
    <div>
      <ImageContainer>
        <StyledImg className="d-flex" alt="" fluid={post.acf.image.localFile.childImageSharp.fluid} />
        <ImageContainerText className="container">
          <div className="title font-size-xxl font-weight-xl">
            <Styledh2 className="color-text-light font-weight-xl">{`${page.title} ${parse(post.title)}`}</Styledh2>
          </div>
        </ImageContainerText>
      </ImageContainer>
      <CustomBreadCrumb data={post.title} className="py-2" pageContext={pageContext} />
      <div className="container mb-5 pt-lg-5 pb-3 pb-lg-5">
        <div className="row mb-5 py-4">
          <div className="col-lg-7">
            <Wachtlijst>
              <WachtlijstForm showSurvival={post.acf.wachtlijst.showSurvival} description={post.acf.wachtlijst.description} id={post.acf.wachtlijst.wachtlijst_id} />
            </Wachtlijst>
          </div>
          <div className="col-lg-5">
            <div>
              <Workings data={page.acf.workings} />
            </div>
          </div>
        </div>
      </div>
      <div className="py-lg-5" />
    </div>
  )
}

const WachtlijstDetail = ({ data, pageContext }) => {
  const { post, page } = data

  return (
    <Layout>
      {post.title.includes('Zwijndrecht') && (
        <Modal />
      )}
      <SEO yoast={{ meta: page.yoastMeta }} pathname={page.path} article />
      <WachtlijstDetailTemplate
        post={post}
        page={page}
        pageContext={pageContext}
      />
    </Layout>
  )
}

export default WachtlijstDetail

export const pageQuery = graphql`
  query DetailWachtlijstQuery($id: String!) {
    post: wordpressWpLocaties(id: {eq: $id}) {
      id
      title
      slug
      acf {
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        wachtlijst {
          wachtlijst_id
          description
          showSurvival: show_survival
        }
      }
    }
    page: wordpressPage(wordpress_id: {eq: 275}) {
        title
        path
        acf {
          workings {
            title
            points {
              text
            }
          }
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }
  }
`