/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'

// CSS
import 'react-datepicker/dist/react-datepicker.css'

// Images
import Calendar from 'img/snorkel_calendar.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { Formik, Form, Field, useField, useFormikContext } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import Checkbox from 'react-checkbox-component'
import moment from 'moment'
import parse from 'html-react-parser'
import ReactModal from 'react-modal'

const StyledWachtlijstForm = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  top: 31px;

  .react-datepicker-wrapper {
    width: 100%;
  }
`

const StyledForm = styled(Form)``

const StyledButton = styled.button`
  background-color: ${props => props.theme.color.contrast};
  width: 200px;
  height: 45px;
  border-radius: 5px;
  color: ${props => props.theme.color.text.light};
  font-weight: ${props => props.theme.font.weight.xl};
  transition: 0.25s;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  &:hover {
    transform: scale(1.05);
  }
`

const StyledCalendar = styled(Calendar)`
  position: absolute;
  right: 27px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  pointer-events: none;
`

const StyledField = styled(Field)`
  background-color: ${props => props.theme.color.secondary};
  height: 45px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.color.border_second};
  padding: 15px 15px 15px 15px;
  color: ${props => props.theme.color.text.main};

  ${props =>
    props.error &&
    css`
      border-color: red;
    `}

  &::placeholder {
    color: ${props => props.theme.color.text.main};
    opacity: 0.6;
    margin-bottom: 0;

    @media screen and (max-width: 991px) {
      position: relative;
      top: 2px;
    }
  }
`

const CustomErrorMessage = styled.div`
  position: absolute;
  color: red;
  display: none;
  font-size: ${props => props.theme.font.size.sm};

  ${props =>
    props.error &&
    css`
      display: block;
    `}
`

const Position = styled.h2``

const SuccessBox = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxl};
  }

  ${Position} {
    font-size: ${props => props.theme.font.size.xxxxl};
  }
`

const ErrorText = styled.h3`
  color: red;
  font-weight: ${props => props.theme.font.weight.xl};
  font-size: ${props => props.theme.font.size.l};
`

const StyledShowSecond = styled.div`
  line-height: 1;

  .title {
    margin-bottom: 10px;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
      margin-bottom: -3px;
    }
  }

  input {
    margin-right: 10px;
  }
`

const SignupSchema = Yup.object().shape({
  child_first_name: Yup.string()
    .min(2, 'Voornaam is te klein')
    .required('Verplicht'),
  child_last_name: Yup.string().required('Verplicht'),
  as_duo: Yup.string(),
  child2_first_name: Yup.string().when('as_duo', {
    is: 'yes',
    then: Yup.string()
      .min(2, 'Voornaam is te klein')
      .required('Verplicht'),
  }),
  child2_last_name: Yup.string().when('as_duo', {
    is: 'yes',
    then: Yup.string().required('Verplicht'),
  }),
  child2_phone: Yup.string().when('as_duo', {
    is: 'yes',
    then: Yup.string()
      .matches(
        /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gm,
        'Telefoonnummer klopt niet'
      )
      .required('Verplicht'),
  }),
  child2_email: Yup.string().when('as_duo', {
    is: 'yes',
    then: Yup.string()
      .email('Emailadres is verkeerd')
      .required('Verplicht'),
  }),
  parent_name: Yup.string()
    .min(2, 'Naam is te klein')
    .max(70, 'Naam is te groot')
    .required('Verplicht'),
  phone: Yup.string()
    .matches(
      /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gm,
      'Telefoonnummer klopt niet'
    )
    .required('Verplicht'),
  email: Yup.string()
    .email('Emailadres is verkeerd')
    .required('Verplicht'),
  birth_date: Yup.string().required('Verplicht'),
  start_date: Yup.string().required('Verplicht'),
  lesson_type: Yup.string().required('Verplicht'),
})

const WachtlijstForm = ({ id, showSurvival, description, className }) => {
  const [gotResult, setGotResult] = useState({ bool: false, result: {} })
  const [showSurvivalPopup, setShowSurvivalPopup] = useState(false)
  const [hasPopupBeenServed, setHasPopupBeenServed] = useState(false)

  let options = [
    { value: 'x' , label: "Geen voorkeur" },
    { value: '1-1', label: 'Eén op één' },
    { value: '1-2', label: 'Eén op twee' },
  ]

  if (showSurvival) {
    options.push({ value: 'survival', label: 'Survivalzwemmen (Eén op twee)' })
  }

  if (id === '1') {
    options = [{ value: '6-baarn', label: 'Baarn (Eén op zes)' }]
  }

  if (id === '10') {
    options.push({ value: '1-3', label: 'Eén op drie'})
    options.push({ value: '1-4', label: 'Eén op vier'})
    options.push({ value: '1-5', label: 'Eén op vijf'})
  }

  useEffect(() => {
      if (showSurvivalPopup) {
        document.querySelector("html").style.overflow = "hidden";
        document.body.style.overflow = 'hidden'
      } else {
        document.querySelector("html").style.overflow = "unset";
        document.body.style.overflow = 'unset'
      }
  }, [showSurvivalPopup])

  return (
    <>
      {parse(description)}
      <StyledWachtlijstForm className={`${className ? `${className}` : ``}`}>
        {gotResult.bool ? (
          <SuccessBox>
            <h2 className="mb-0 pb-0 font-weight-xl text-center">{`${gotResult.result.child_first_name} ${gotResult.result.child_last_name}`}</h2>
            <h2 className="mb-0 font-weight-s text-center">
              Staat nu ingeschreven
            </h2>
            <p className="mb-0 pt-5 text-center">Jullie staan op positie</p>
            <Position className="font-weight-xl text-center pb-5">
              {gotResult.result.position}
            </Position>
            <p className="mb-0 text-center">
              {' '}
              Jullie hebben een mail ontvangen met meer informatie
            </p>
            <p className="mb-0 text-center">
              {' '}
              <em>
                Het kan voorkomen dat deze mail in jullie SPAM binnenkomt.
              </em>
            </p>
          </SuccessBox>
        ) : (
          <>
            {gotResult.result.errors && (
              <ErrorText>{gotResult.result.errors.email}</ErrorText>
            )}
            <Formik
              initialValues={{ pfdays: [] }}
              validationSchema={SignupSchema}
              onSubmit={async (values, { setSubmitting }) => {
                // const url = `http://127.0.0.1:8000/api/v1/form/${id}`
                const url = `https://wachtlijst.zwemschoolsnorkeltje.nl/api/v1/form/${id}`

                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(values),
                })

                if (response.ok) {
                  const resultValue = await response.json()
                  setGotResult({ bool: true, result: resultValue })
                  setSubmitting(false)
                } else {
                  const resultValue = await response.json()
                  setGotResult({ bool: false, result: resultValue })
                  setSubmitting(false)
                }
              }}
            >
              {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                <StyledForm className="row">
                  <div className="col-md-6 mb-4">
                    <StyledField
                      error={
                        errors.child_first_name !== undefined &&
                        touched.child_first_name
                      }
                      placeholder="Voornaam van het kind"
                      className="w-100"
                      type="text"
                      name="child_first_name"
                    />
                    <CustomErrorMessage
                      error={
                        errors.child_first_name !== undefined &&
                        touched.child_first_name
                      }
                    >
                      {errors.child_first_name}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-6 mb-4">
                    <StyledField
                      error={
                        errors.child_last_name !== undefined &&
                        touched.child_last_name
                      }
                      placeholder="Achternaam van het kind"
                      className="w-100"
                      type="text"
                      name="child_last_name"
                    />
                    <CustomErrorMessage
                      error={
                        errors.child_last_name !== undefined &&
                        touched.child_last_name
                      }
                    >
                      {errors.child_last_name}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-12 mb-4">
                    <StyledField
                      error={
                        errors.parent_name !== undefined && touched.parent_name
                      }
                      placeholder="Naam ouder"
                      className="w-100"
                      type="text"
                      name="parent_name"
                    />
                    <CustomErrorMessage
                      error={
                        errors.parent_name !== undefined && touched.parent_name
                      }
                    >
                      {errors.parent_name}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-12 mb-4">
                    <StyledField
                      error={errors.phone !== undefined && touched.phone}
                      placeholder="Telefoonnummer"
                      className="w-100"
                      type="text"
                      name="phone"
                    />
                    <CustomErrorMessage
                      error={errors.phone !== undefined && touched.phone}
                    >
                      {errors.phone}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-12 mb-4">
                    <StyledField
                      error={errors.email !== undefined && touched.email}
                      placeholder="E-mailadres"
                      className="w-100"
                      type="text"
                      name="email"
                    />
                    <CustomErrorMessage
                      error={errors.email !== undefined && touched.email}
                    >
                      {errors.email}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-6 mb-4 position-relative">
                    <StyledDatePickerField
                      error={
                        errors.birth_date !== undefined && touched.birth_date
                      }
                      maxDate={new Date()}
                      placeholderText="Geboortedatum"
                      className="w-100"
                      name="birth_date"
                    />
                    <StyledCalendar />
                    <CustomErrorMessage
                      error={
                        errors.birth_date !== undefined && touched.birth_date
                      }
                    >
                      {errors.birth_date}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-6 mb-4">
                    <StyledDatePickerField
                      error={
                        errors.start_date !== undefined && touched.start_date
                      }
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      allowSameDay="false"
                      placeholderText="Gewenste startdatum"
                      className="w-100"
                      name="start_date"
                    />
                    <StyledCalendar />
                    <CustomErrorMessage
                      error={
                        errors.start_date !== undefined && touched.start_date
                      }
                    >
                      {errors.start_date}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-6 mb-4">
                    <StyledSelectField
                      error={
                        errors.lesson_type !== undefined && touched.lesson_type
                      }
                      placeholder="Type les"
                      className="w-100"
                      name="lesson_type"
                      options={options}
                      onChange={value => {
                        if (value === 'survival') {
                          return
                        }

                        const isChildFourAndHalfYearsOld = moment(
                          values.birth_date
                        ).isBefore(
                          moment()
                            .subtract(4, 'years')
                            .subtract(6, 'months')
                        )

                        if (!hasPopupBeenServed) {
                          setShowSurvivalPopup(!isChildFourAndHalfYearsOld)
                          setHasPopupBeenServed(true)
                        }
                      }}
                    />
                    <CustomErrorMessage
                      error={
                        errors.lesson_type !== undefined && touched.lesson_type
                      }
                    >
                      {errors.lesson_type}
                    </CustomErrorMessage>
                  </div>

                  <div className="col-md-6 d-flex align-items-center mb-4">
                    {(values.lesson_type === 'survival' ||
                      values.lesson_type === '1-2') && (
                      <StyledShowSecond htmlFor="as_duo" className="mb-0">
                        <span className="title line-height-1 d-block">
                          Hebben jullie zelf al een koppel?
                        </span>

                        <label htmlFor="as_duo_yes">
                          <input
                            id="as_duo_yes"
                            type="radio"
                            name="as_duo"
                            value="yes"
                            checked={values.as_duo === 'yes'}
                            onChange={e =>
                              setFieldValue('as_duo', e.target.value)}
                          />
                          <span>Ja</span>
                        </label>

                        <label htmlFor="as_duo_no">
                          <input
                            id="as_duo_no"
                            type="radio"
                            name="as_duo"
                            value="no"
                            checked={values.as_duo === 'no'}
                            onChange={e =>
                              setFieldValue('as_duo', e.target.value)}
                          />
                          <span>Nee</span>
                        </label>
                      </StyledShowSecond>
                    )}
                  </div>

                  {values.as_duo === 'yes' &&
                    (values.lesson_type === 'survival' ||
                      values.lesson_type === '1-2') && (
                      <>
                        <div className="col-md-6 mb-4">
                          <StyledField
                            error={
                              errors.child2_first_name !== undefined &&
                              touched.child2_first_name
                            }
                            placeholder="Voornaam van het tweede kind"
                            className="w-100"
                            type="text"
                            name="child2_first_name"
                          />
                          <CustomErrorMessage
                            error={
                              errors.child2_first_name !== undefined &&
                              touched.child2_first_name
                            }
                          >
                            {errors.child2_first_name}
                          </CustomErrorMessage>
                        </div>

                        <div className="col-md-6 mb-4">
                          <StyledField
                            error={
                              errors.child2_last_name !== undefined &&
                              touched.child2_last_name
                            }
                            placeholder="Achternaam van het tweede kind"
                            className="w-100"
                            type="text"
                            name="child2_last_name"
                          />
                          <CustomErrorMessage
                            error={
                              errors.child2_last_name !== undefined &&
                              touched.child2_last_name
                            }
                          >
                            {errors.child2_last_name}
                          </CustomErrorMessage>
                        </div>

                        <div className="col-md-12 mb-4">
                          <StyledField
                            error={
                              errors.child2_phone !== undefined &&
                              touched.child2_phone
                            }
                            placeholder="Telefoonnummer van ouder tweede kind"
                            className="w-100"
                            type="text"
                            name="child2_phone"
                          />
                          <CustomErrorMessage
                            error={
                              errors.child2_phone !== undefined &&
                              touched.child2_phone
                            }
                          >
                            {errors.child2_phone}
                          </CustomErrorMessage>
                        </div>

                        <div className="col-md-12 mb-4">
                          <StyledField
                            error={
                              errors.child2_email !== undefined &&
                              touched.child2_email
                            }
                            placeholder="E-mailadres van ouder tweede kind"
                            className="w-100"
                            type="text"
                            name="child2_email"
                          />
                          <CustomErrorMessage
                            error={
                              errors.child2_email !== undefined &&
                              touched.child2_email
                            }
                          >
                            {errors.child2_email}
                          </CustomErrorMessage>
                        </div>
                      </>
                    )}

                  <div className="col-12 d-flex justify-content-center justify-content-md-start pt-4">
                    <StyledButton type="submit" disabled={isSubmitting}>
                      Verzenden
                    </StyledButton>
                  </div>
                </StyledForm>
              )}
            </Formik>
          </>
        )}
      </StyledWachtlijstForm>

      {showSurvivalPopup && <SurvivalModal onClose={() => setShowSurvivalPopup(false)} isOpen={showSurvivalPopup} />}
    </>
  )
}

export default WachtlijstForm

const DatePickerField = ({ onChange, ...rest }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(rest)
  return (
    <DatePicker
      {...field}
      {...rest}
      autoComplete="off"
      dateFormat="dd-MM-yyyy"
      value={moment(field.value).toDate()}
      selected={(field.value && moment(field.value).toDate()) || null}
      onChange={val => {
        setFieldValue(field.name, moment(val).format('YYYY-MM-DD HH:mm:ss'))
        if (onChange) onChange(val)
      }}
    />
  )
}

const StyledDatePickerField = styled(DatePickerField)`
  background-color: ${props => props.theme.color.secondary};
  height: 45px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.color.border_second};
  padding: 0 15px 0 15px;
  color: ${props => props.theme.color.text.main};

  ${props =>
    props.error &&
    css`
      border-color: red;
    `}

  &::placeholder {
    color: ${props => props.theme.color.text.main};
    opacity: 0.6;
  }
`

const SelectField = ({ options, onChange, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  return (
    <Select
      {...field}
      {...props}
      options={options}
      classNamePrefix="react-select"
      value={
        options ? options.find(option => option.value === field.value) : ''
      }
      onChange={option => {
        setFieldValue(field.name, option.value)
        if (onChange) onChange(option.value)
      }}
      onBlur={field.onBlur}
    />
  )
}

const StyledSelectField = styled(SelectField)`
  background-color: ${props => props.theme.color.secondary};
  height: 45px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.color.border_second};
  color: ${props => props.theme.color.text.main};

  ${props =>
    props.error &&
    css`
      border-color: red;
    `}

  .react-select__control {
    border: none;
    height: 100%;
  }

  .react-select__value-container {
    padding: 0 15px 0 15px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding-right: 13px;
  }

  .react-select__placeholder {
    color: ${props => props.theme.color.text.main};
    opacity: 0.6;
  }
`

const StyledCheckboxField = styled.div`
  .checkbox {
    border-radius: 40px !important;
    width: 15px !important;
    height: 15px !important;
    border: 1px solid ${props => props.theme.color.border_second};
    background-color: ${props => props.theme.color.lighter_gray};

    &-selected {
      background-color: ${props => props.theme.color.contrast};
      width: 15px !important;
      height: 15px !important;
      border-radius: 40px !important;
      top: -1px;
      left: -1px;
    }
  }
`

const CheckboxField = ({ value, label, dayName, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  return (
    <StyledCheckboxField className="d-flex align-items-center">
      <Checkbox
        {...field}
        {...props}
        isChecked={field.value.find(e => e === value)}
        onChange={val => {
          if (val) {
            field.value.push(value)
            setFieldValue(field.name, field.value)
          } else {
            field.value.splice(field.value.indexOf(value), 1)
            setFieldValue(field.name, field.value)
          }
        }}
      />
      <p className="mb-0 ml-2">{label}</p>
    </StyledCheckboxField>
  )
}


const StyledLink = styled.a`
  background-color: ${props => props.theme.color.contrast};
  width: 150px;
  height: 32px;
  border-radius: 5px;
  color: ${props => props.theme.color.text.light};
  font-weight: ${props => props.theme.font.weight.xl};
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
  margin-top: 15px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  &:hover {
    transform: scale(1.05);
    color: ${props => props.theme.color.text.light};
    text-decoration: none;
  }
`


function SurvivalModal({ isOpen, onClose }) {
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
      position: 'absolute',
      top: '40px',
      left: '40px',
      right: '40px',
      bottom: '40px',
      border: '1px solid #ccc',
      background: '#fff',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px',
      width: '500px',
      height: '325px',
      margin: 'auto',
      overflow: 'hidden'
    }
  };


  return (
    <ReactModal defaultStyles={customStyles} isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title" style={{ color: "#FF5C00" }}>Mini Survival zwemles</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            className="feather feather-x"
            viewBox="0 0 24 24"
          >
            <path d="M18 6L6 18" />
            <path d="M6 6L18 18" />
          </svg>
        </button>
      </div>
      <div className="modal-body overflow-hidden">
        <p>
          Wij zien dat uw zoon/dochter nog geen 4,5 jaar oud is. Uw zoon/dochter is nu nog te jong voor de echte zwemlessen.
          <br />
          <br />
          {' '}
          Wisten jullie dat wij ook Mini Survival zwemles aanbieden voor kinderen onder de 4,5 jaar oud. Dit is een cursus waarbij kinderen bewust worden gemaakt wat water inhoudt

          <StyledLink target="_blank" href="https://www.zwemschoolsnorkeltje.nl/lesaanbod/mini-survival-zwemmen">
            Meer informatie
            <span className='ml-2'>{'>'}</span>
          </StyledLink>
        </p>
      </div>      
    </ReactModal>
  )
}
